.largeur-100 {
    width: calc(100vw - 30px);
}

@media screen and (max-width: 767px) {

    .largeur-100 {
      
    }

}


.bold {
    font-weight: 700;
    line-height: 1.7;
}

.bold a, .bold a:hover {
    text-decoration: none;
    color: #1d1d1d;
}

.menu-container ul {
    list-style-type: none;
}

.menu-container a, .menu-container a:hover, .menu-container span {
    display: inline-block;
    text-decoration: none;
    color: #131313;
    font-size: 14px;
    letter-spacing: .75px;
    margin-bottom: 7px;
    text-transform: none;
}

.header-container .has-children {
    margin-top: 20px;
    border-top: 2px solid #d2d2d3;
}

.menu-container .has-children > a {
    color: #13135f;
    margin-top: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.menu-container ul {
    padding-left: 0;
}

.menu-container {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

.menu-open {
    max-height: 1000px; /* Ajustez la valeur en fonction de la hauteur du contenu */
    opacity: 1;
}

@media screen and (max-width: 767px) {

    .menu-open {
        max-height: 3000px; /* Ajustez la valeur en fonction de la hauteur du contenu */
    }

}

.menu-closed {
    max-height: 0;
    opacity: 0;
}

.menu-container .column4-menu {
    margin-top: 30px;
    font-weight: bold;
    line-height: 3;
}

.menu-container .column4-menu a {
    color: #13135f;
}


